@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital@0;1&family=League+Spartan&display=swap');

body {
  /* margin: 0; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: "Alegreya", serif;
    /* font-optical-sizing: auto; */
    font-weight: 400;
    font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.imageStack {
  display: flex;
  animation: slideImages 10s linear infinite;
  overflow: auto;
}

@keyframes slideImages {
  0% { transform: translateX(-5%); }
  10% { transform: translateX(-10%); }
  20% { transform: translateX(-20%); }
  30% { transform: translateX(-30%); }
  40% { transform: translateX(-40%); }
  50% { transform: translateX(-50%); }
  60% { transform: translateX(-60%); }
  70% { transform: translateX(-70%); }
  80% { transform: translateX(-80%); }
  90% { transform: translateX(-90%); }
  100% { transform: translateX(-100%); }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
